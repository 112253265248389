import { useNavigate } from "react-router-dom";
import { Image } from "react-bootstrap";
import logo2 from "../../../images/logos/qsbc-logo.png";
import logo3 from "../../../images/logos/rics-logo.png";
import logo4 from "../../../images/logos/ciqs-logo.png";
import Slider from "./Slider";
import "./Home.css";

const Home = () => {
  const navigate = useNavigate();

  const costIndexClickHandler = () => {
    localStorage.setItem("calculator", "ci");
    navigate("/tools");
  };

  return (
    <section id="home">
      <h2>Experience and a record of excellence</h2>
      <h6>BDC helping Vancouver grow for over {new Date().getFullYear() -1988}  years.</h6>

      <div id="ciButton">
        <button className="pushable" onClick={costIndexClickHandler}>
          <span className="shadow"></span>
          <span className="edge"></span>
          <span className="front">COST INDEX CALCULATOR</span>
        </button>
      </div>

      <div id="main" className="container">
        <Slider />
      </div>
      <div id="logos">
        <Image src={logo2}></Image>
        <Image src={logo3}></Image>
        <Image src={logo4}></Image>
      </div>
    </section>
  );
};

export default Home;
