const MortgageResultTable = (props) => {
  return (
    <div id="mortgageResult">
      <div><span><b>Down Payment Required: </b></span> ${props.amortization}</div>
      <div><span><b>Mortgage Principal: </b></span> ${props.principal}</div>
      <div><span><b>Monthly Payment: </b></span> ${props.payment}</div>
      <div><span><b>Still Owing at End of Term: </b></span> ${props.amount}</div>
    </div>
  );
};

export default MortgageResultTable;
