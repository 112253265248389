import React, { useEffect, useState } from "react";
import {
  loadProject,
  filterProjectsByLocation,
  filterProjectsByType,
  filterProjectsByDeveloper,
  clearAllTimeouts,
} from "./ProjectsFunctions";
import { projects, displayedProjects } from "./ProjectsFunctions";
import featuredProjects, { images } from "./ProjectsData";
import { Image, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowCircleLeft,
  faArrowCircleRight,
} from "@fortawesome/free-solid-svg-icons";
import ProjectsLocationFilter from "./ProjectsLocationFilter";
import ProjectsTypeFilter from "./ProjectsTypeFilter";
import ProjectsDeveloperFilter from "./ProjectsDeveloperFilter";
import "./Projects.css";
import ImageGallery from 'react-image-gallery';

let arrayForHoldingProjects = [];

const Projects = () => {

  const [currentImage, setCurrentImage] = useState(0);

  const PROJECT_LOADING_TIME = 3000;
  const PHOTO_LOADING_TIME = 500;
  const MIN_WIDTH = 1134;
  const projectsPerPage = 12;

  const [projectsDisplayed, setProjectsDisplayed] = useState([]);
  const [filteredProjects, setFilteredProjects] = useState(featuredProjects);
  const [next, setNext] = useState(projectsPerPage);
  const [filtered, setFiltered] = useState(false);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [index, setIndex] = useState(0);
  
  let buttonPressed = false;

  const nextImage = () => {
    setCurrentImage((currentImage + 1) % featuredProjects.length);
  };

  const prevImage = () => {
    setCurrentImage((currentImage - 1 + featuredProjects.length) % featuredProjects.length);
  };

  const closeHandler = () => {
    buttonPressed = false;
    setShow(false);
  };
  const showHandler = () => setShow(true);

  const showMoreHandler = () => {
    loopWithSlice(next, next + projectsPerPage);
    setNext(next + projectsPerPage);

    const loadButton = document.getElementById("loadBtn");
    loadButton.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  const keyDownHandler = (event) => {
    if (event.defaultPrevented) {
      return; // Do nothing if the event was already processed
    }

    switch (event.key) {
      case "ArrowDown":
        // Do something for "down arrow" key press.
        closeHandler();
        break;
      case "ArrowUp":
        // Do something for "up arrow" key press.
        closeHandler();
        break;
      case "ArrowLeft":
        // Do something for "left arrow" key press.
        buttonPressed = true;
        showModal(index - 1);
        break;
      case "ArrowRight":
        // Do something for "right arrow" key press.
        buttonPressed = true;
        showModal(index + 1);
        break;
      case "Escape":
        // Do something for "esc" key press.
        closeHandler();
        break;
      default:
        return; // Quit when this doesn't handle the key event.
    }

    // Cancel the default action to avoid it being handled twice
    event.preventDefault();
  };

  const locationFilterHandler = (tag) => {
    filterProjects(filterProjectsByLocation, tag);
  };

  const typeFilterHandler = (tag) => {
    filterProjects(filterProjectsByType, tag);
  };

  const developerFilterHandler = (tag) => {
    filterProjects(filterProjectsByDeveloper, tag);
  };

  const showModal = (i) => {
    if (buttonPressed) {
      if (i < 0) i = filteredProjects.length - 1;
      if (i == filteredProjects.length) i = 0;
    }

    setIndex(i);
    showHandler();
    setTimeout(loadPhoto);
  };

  const loadPhoto = () => {
    document.getElementById("modal").focus();
    let photoWidth =
      document.getElementsByClassName("projectPhoto")[0].naturalWidth;
    let photoHeight =
      document.getElementsByClassName("projectPhoto")[0].naturalHeight;

    let screenWidth = window.innerWidth;
    let screenHeight = window.innerHeight;

    if (screenWidth > MIN_WIDTH) {
      if (photoWidth >= screenWidth) {
        photoWidth = screenWidth * 0.65;
      } else if (photoHeight >= screenHeight * 0.8) {
        photoWidth *= 0.65;
      }

      if (photoWidth != 0) {
        document.getElementsByClassName("photo-modal")[0].style.maxWidth =
          photoWidth + "px";
      } else {
        setTimeout(loadPhoto, PHOTO_LOADING_TIME);
      }
    }
  };

  const loopWithSlice = (start, end) => {
    const slicedProjects = projects.slice(start, end);
    arrayForHoldingProjects = [...arrayForHoldingProjects, ...slicedProjects];
    setProjectsDisplayed(arrayForHoldingProjects);
  };

  const filterProjects = (filterFunction, tag) => {
    arrayForHoldingProjects = [];
    setNext(projectsPerPage);
    setLoading(true);
    setFiltered(true);
    setProjectsDisplayed([]);
    clearAllTimeouts();

    if (window.innerWidth > MIN_WIDTH) window.scrollTo(0, 0);
    else window.scrollTo(0, 210);

    try {
      filterFunction(tag, showModal).then((result) => {
        projects.sort((a, b) => parseInt(b.key) - parseInt(a.key));
        displayedProjects.sort(
          (a, b) => parseInt(b.number) - parseInt(a.number)
        );

        setLoading(false);
        loopWithSlice(0, projectsPerPage);
        setFilteredProjects(displayedProjects);
      });
    } catch {
      window.location.href = "/projects";
    }
  };

  useEffect(() => {
    // Runs after the first render() lifecycle
    const recent = localStorage.getItem("recentKey");
    const recentIndex = localStorage.getItem("recentKeyIndex");

    loadProject(showModal);
    setProjectsDisplayed(projects);

    if (recent != "" && recent != null) {
      locationFilterHandler(recent);
      localStorage.setItem("recentKey", "");
      setTimeout(() => {
        const recentProjectIndex = displayedProjects.findIndex(
          ({ number }) => number == recentIndex
        );
        showModal(recentProjectIndex);
      }, PROJECT_LOADING_TIME);
    }
    // return () => {
    //   arrayForHoldingProjects = [];
    //   localStorage.clear();
    // };
  }, []);

  return (
    <React.Fragment>
      <div id="projects">
        <div className="row">
          <div className="columnFilter" id="filter">
            <ProjectsLocationFilter filterFunction={locationFilterHandler} />
            <ProjectsTypeFilter filterFunction={typeFilterHandler} />
            <ProjectsDeveloperFilter filterFunction={developerFilterHandler} />
          </div>
          <div className="columnProjects">
            {/* <h4 id="title">Featured Projects</h4> */}

            {loading && <div className="loader"></div>}

            <div id="featured" >

              {/* <div className="carousel">
                  <button onClick={prevImage}>Previous</button>
                    <img src={featuredProjects[currentImage].path} alt="carousel" />
                  <button onClick={nextImage}>Next</button>
              </div> */}

              {!filtered && <ImageGallery items={images} autoPlay = {true} showPlayButton = {false} showFullscreenButton = {true} description={"Featured Projects"} />}
              { filtered && projectsDisplayed.map((project) => project)} 
              
              {arrayForHoldingProjects.length > 0 && next < projects.length && (
                <div id="loadBtn">
                  <button
                    className="btn btn-primary myButton"
                    onClick={showMoreHandler}
                  >
                    Load more
                  </button>
                </div>
              )}
              {filteredProjects[index] && (
                <Modal
                  id="modal"
                  show={show}
                  onHide={closeHandler}
                  onKeyDown={keyDownHandler}
                  tabIndex={-1}
                  dialogClassName="photo-modal"
                  centered
                >
                  <Modal.Header closeButton>
                    <Modal.Title>
                      <h5 className="projectTitle">
                        {filteredProjects[index].name}
                      </h5>
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    {filteredProjects.length > 1 && (
                      <FontAwesomeIcon
                        id="left"
                        className="arrow"
                        icon={faArrowCircleLeft}
                        onClick={() => {
                          buttonPressed = true;
                          showModal(index - 1);
                        }}
                      />
                    )}
                    <div className="photo-container">
                      <Image
                        className="projectPhoto"
                        src={filteredProjects[index].path}
                        alt={filteredProjects[index].title}
                      ></Image>
                    </div>
                    {filteredProjects.length > 1 && (
                      <FontAwesomeIcon
                        id="right"
                        className="arrow"
                        icon={faArrowCircleRight}
                        onClick={() => {
                          buttonPressed = true;
                          showModal(index + 1);
                        }}
                      />
                    )}
                  </Modal.Body>
                  <Modal.Footer
                    style={{
                      color: "gray",
                      display: "flex",
                      justifyContent: "left",
                    }}
                  >
                    <p>{filteredProjects[index].description}</p>
                  </Modal.Footer>
                </Modal>
              )}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Projects;
