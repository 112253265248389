import { IntroData } from "../IntroData";
import { JobData } from "./JobData";
import Job from "./Job";
import "./Careers.css";

const Careers = () => {
  return (
    <section id="careers">
      <p className="intro">{IntroData["careers"]}</p>
      <h3>Realize your potential</h3>
      <div className="container" id="jobs">
        <div className="row">
          <div className="col-sm-12">
            <br />
            <br />
            <div className="row">
              <div className="col-sm-6">
                <Job
                  id="senior"
                  title="Senior Quantity Surveyor"
                  description={JobData["senior"]}
                />
              </div>
              <div className="col-sm-6">
                <div className="col-sm-12">
                  <div className="row">
                    <div className="col-sm-12">
                      <Job
                        id="junior"
                        title="Junior Quantity Surveyor"
                        description={JobData["junior"]}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-12">
                      <Job
                        id="intermediate"
                        title="Intermediate Quantity Surveyor/Estimator"
                        description={JobData["intermediate"]}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Careers;
