import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Service = (props) => {
  const clickHandler = () => {
    props.myFunction(props.index, props.paragraph);
  };
  return (
    <div className="col-sm-4 service-container">  
        <div className="box-gray aligncenter service" id={props.name}>
          <h4>{props.title}</h4>
          <div className="icon">
            <FontAwesomeIcon icon={props.icon} onClick={clickHandler} />
          </div>
        </div>
        <div className="box-bottom"></div>     
    </div>
  );
};

export default Service;
