export const costIndexRate = [
  { cibase: 0.93, cilq: 0.96, cimq: 1, cihq: 1.1 },
  { cibase: 1, cilq: 0.96, cimq: 1, cihq: 1.12 },
  { cibase: 0.97, cilq: 0.97, cimq: 1, cihq: 1.11 },
  { cibase: 0.9, cilq: 0.95, cimq: 1, cihq: 1.06 },
  { cibase: 0.95, cilq: 0.95, cimq: 1, cihq: 1.08 },
  { cibase: 0.91, cilq: 0.93, cimq: 1, cihq: 1.07 },
  { cibase: 1.04, cilq: 0.947, cimq: 1, cihq: 1.13 },
  { cibase: 1.2, cilq: 0.93, cimq: 1, cihq: 1.08 },
  { cibase: 0.94, cilq: 0.95, cimq: 1, cihq: 1.04 },
  { cibase: 0.95, cilq: 0.94, cimq: 1, cihq: 1.05 },
  { cibase: 0.96, cilq: 0.94, cimq: 1, cihq: 1.05 },
  { cibase: 1.02, cilq: 0.97, cimq: 1, cihq: 1.07 },
  { cibase: 0.97, cilq: 0.96, cimq: 1, cihq: 1.04 },
  { cibase: 1.25, cilq: 0.98, cimq: 1, cihq: 1.15 },
  { cibase: 1.15, cilq: 0.97, cimq: 1, cihq: 1.12 },
  { cibase: 1.2, cilq: 0.98, cimq: 1, cihq: 1.3 },
  { cibase: 0.98, cilq: 0.95, cimq: 1, cihq: 1.08 },
  { cibase: 1.12, cilq: 0.95, cimq: 1, cihq: 1.17 },
  { cibase: 1.16, cilq: 0.94, cimq: 1, cihq: 1.11 },
  { cibase: 1.01, cilq: 0.97, cimq: 1, cihq: 1.08 },
  { cibase: 0.94, cilq: 0.94, cimq: 1, cihq: 1.04 },
];

export const costIndexBaseCost = [
  400, 410, 420, 430, 420, 430, 435, 445, 405, 415, 440, 460, 330, 340, 370, 
  390, 300, 320, 500, 510, 490, 410, 440, 465, 380, 395, 510, 485, 475, 510, 
  485, 385, 395, 395, 420, 380, 365, 290, 280, 365, 385, 365, 375, 260, 240,
];

export const buildingTypes = [
  "Concrete Residences 12 to 20 Storeys, Above Ground Parking, Average unit size Over 1000 s.f.",
  "Concrete Residences 12 to 20 Storeys, Above Ground Parking, Average unit size Up to 1000 s.f.",
  "Concrete Residences 12 to 20 Storeys, Below Ground Parking, Average unit size Over 1000 s.f.",
  "Concrete Residences 12 to 20 Storeys, Below Ground Parking, Average unit size Up to 1000 s.f.",
  "Concrete Residences Over 20 Storeys, Above Ground Parking, Average unit size Over 1000 s.f.",
  "Concrete Residences Over 20 Storeys, Above Ground Parking, Average unit size Up to 1000 s.f.",
  "Concrete Residences Over 20 Storeys, Below Ground Parking, Average unit size Over 1000 s.f.",
  "Concrete Residences Over 20 Storeys, Below Ground Parking, Average unit size Up to 1000 s.f.",
  "Concrete Residences up to 12 Storeys, Above Ground Parking, Average unit size Over 1000 s.f.",
  "Concrete Residences up to 12 Storeys, Above Ground Parking, Average unit size Up to 1000 s.f.",
  "Concrete Residences up to 12 Storeys, Below Ground Parking, Average unit size Over 1000 s.f.",
  "Concrete Residences up to 12 Storeys, Below Ground Parking, Average unit size Up to 1000 s.f.",
  "Framed Apartment, With Above Ground Concrete Parking Structure, Average unit size Over 1000 s.f.",
  "Framed Apartment, With Above Ground Concrete Parking Structure, Average unit size Up to 1000 s.f.",
  "Framed Apartment, With Below Ground Concrete Parking Structure, Average unit size Over 1000 s.f.",
  "Framed Apartment, With Below Ground Concrete Parking Structure, Average unit size Up to 1000 s.f.",
  "Framed Apartment, Without Concrete Parking Structure, Average unit size Over 1000 s.f.",
  "Framed Apartment, Without Concrete Parking Structure, Average unit size Up to 1000 s.f.",
  "Hotel, Business Function, All",
  "Hotel, Resort Type, All",
  "Intermediate Care, Concrete Structures, All",
  "Intermediate Care, Framed Apartments, All",
  "Mixed Use: Commercial/Residential, With Underground Parking, Average unit size Over 1000 s.f.",
  "Mixed Use: Commercial/Residential, With Underground Parking, Average unit size Up to 1000 s.f.",
  "Mixed Use: Commercial/Residential, Without Underground Parking, Average unit size Over 1000 s.f.",
  "Mixed Use: Commercial/Residential, Without Underground Parking, Average unit size Up to 1000 s.f.",
  "Office, Highrise over 12 Storeys, All",
  "Office, Highrise up to 12 Storeys, All",
  "Office, Lowrise, All",
  "Seniors Housing, Concrete Structures, All",
  "Seniors Housing, Framed Apartments, All",
  "Single Family Home, On Grade, Over 2500 s.f.",
  "Single Family Home, On Grade, Up to 2500 s.f.",
  "Single Family Home, With Basement, Over 2500 s.f.",
  "Single Family Home, With Basement, Up to 2500 s.f.",
  "Strip Commercial, With Large Anchor Tenant, All",
  "Strip Commercial, Without Large Anchor Tenant, All",
  "TownHouses, On Grade, Average unit size Over 1200 s.f.",
  "TownHouses, On Grade, Average unit size Up to 1200 s.f.",
  "TownHouses, Over Common Parking Structure, Average unit size Over 1200 s.f.",
  "TownHouses, Over Common Parking Structure, Average unit size Up to 1200 s.f.",
  "TownHouses, With Concrete Basement, Average unit size Over 1200 s.f.",
  "TownHouses, With Concrete Basement, Average unit size Up to 1200 s.f.",
  "Warehouses, With 20% Office, All",
  "Warehouses, Without Office Component, All"
];

export const buildingLocations = ["Abbotsford","Burnaby","Coquitlam","Kelowna","Langley","Nanaimo","North Vancouver","Northern BC","Pitt Meadows / Maple Ridge","Port Coquitlam","Port Moody","Richmond","Surrey","Vancouver - Downtown","Vancouver - East Side","Vancouver - West Side","Victoria","West Vancouver","Whistler","White Rock","New Westminster"];
