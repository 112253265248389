import React, { useState } from "react";
import { calculateCostIndex } from "./ToolsLogicFunctions";
import { buildingTypes, buildingLocations } from "./ToolsData";
import { IntroData } from "../IntroData";

const CostIndexCalculator = (props) => {
  const [submitted, setSubmit] = useState(false);
  const [result, setResult] = useState();

  const submitHandler = (event) => {
    const tableData = calculateCostIndex(() => setSubmit(false));
    setResult(tableData);
    setSubmit(true);
    event.preventDefault();
  };

  const ciForm = (
    <form onSubmit={submitHandler}>
      <label>Building Type / Size</label>
      <select id="cibuilding" name="cibuilding" required>
        <option value="">Select a building type/size</option>

        {buildingTypes.map((buildingType, index) => (
          <React.Fragment key={"t" + index}>
            <option value={index + 1}>{buildingType}</option>
          </React.Fragment>
        ))}

      </select>
      <br />
      <br />

      <label>Quality</label>
      <select id="ciquality" name="ciquality" required>
        <option value="">Select a quality</option>
        <option value="lq">Low Quality</option>
        <option value="mq">Medium Quality</option>
        <option value="hq">High Quality</option>
      </select>
      <br />
      <br />

      <label>Location</label>
      <select id="cilocation" name="cilocation" required>
        <option value="">Select a location</option>

        {buildingLocations.map((buildingLocation, index) => (
          <React.Fragment key={"l" + index}>
            <option value={index + 1}>{buildingLocation}</option>
          </React.Fragment>
        ))}

      </select>
      <br />
      <br />
      <div id="inputs">
        <input type="submit" className="btn btn-primary myButton" />
        <input
          type="button"
          value="Cancel"
          className="btn btn-primary myButton cancelBtn"
          onClick={props.clickHandler}
        />
      </div>
    </form>
  );

  return (
    <div>
      <div>
        <p>{IntroData["costIndex"]}</p>
      </div>
      <div>{submitted ? result : ciForm}</div>
    </div>
  );
};

export default CostIndexCalculator;
