import ProjectsFilter from "./ProjectsFilter";

const ProjectsLocationFilter = (props) => {
  return (
    <div id="location">
      <h5>Location</h5>

      <ProjectsFilter
        id="bc"
        text="Interior British Columbia"
        myFunction={props.filterFunction}
      />
      <ProjectsFilter
        id="vlm"
        text="Vancouver Lower Mainland"
        myFunction={props.filterFunction}
      />
      <ProjectsFilter
        id="vi"
        text="Vancouver Island"
        myFunction={props.filterFunction}
      />
      <ProjectsFilter
        id="on"
        text="Ontario"
        myFunction={props.filterFunction}
      />
      <ProjectsFilter
        id="us"
        text="United States"
        myFunction={props.filterFunction}
      />
      <ProjectsFilter
        id="wc"
        text="Western Canada"
        myFunction={props.filterFunction}
      />
    </div>
  );
};

export default ProjectsLocationFilter;
