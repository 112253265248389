export const JobData = {
  senior: `A great opportunity for an ambitious individual with partnership aspirations.

    You are a seasoned professional with a broad skill set in all
    aspects of Quantity Surveying. Experienced in Estimating, Monitoring
    and Contract Administration.
 
    Technical abilities include report production on a variety of
    software systems.
  
    A self starter with engaging leadership, teamwork and communication
    skills.
 
    Comfortable liasing directly with clients. Strong communication
    skills. RICS/ CIQS membership.`,
  junior:
    "An opportunity for an individual, currently pursuing professional status, to take on a variety of tasks, developing skills and learning from Surveyors with a lengthy knowledge in the construction industry.",
  intermediate:
    "An opportunity for an individual ready to move up to a more senior role. Experienced in estimating in both Trade and Elemental formats.",
};
