export const IntroData = {
  people:
    "BDC’s greatest asset is its people, who possess an exceptional depth of knowledge and wealth of experience in the construction field. Please click on a picture to view bio’s and contact information.",
  services:
    "We provide unbiased independent advice to the development industry, so that building owners are served by increased productivity and efficiency in the design and construction process. Our services allow financial institutions, developers, building owners and designers to apply effective control and accountability to the development process from the planning stage, through construction, and in the managmenent of successfully completed buildings.",
  careers: `BDC has been providing the highest standard of services, to industry leading clients for over 30 years. 
   We offer superior benefits and salary packages commensurate with expertise, qualifications, skills and potential for growth.
   Please enquire, with confidentiality, to ian@bdconsultants.com.`,
  tools: `BDC is pleased to provide the following tools for your use.
   These services are free and meant as a rough guide only.
   For more accurate assessments, please contact an associate at BDC for a more accurate assessment.`,
  costIndex: `Through years of industry experience BDC has developed the BDC Construction Cost Index Service as an easy way for construction professionals to roughly estimate the per square foot cost of a project based on simple criteria. This service is free and is meant as a rough guide only, please contact an associate at BDC for a more accurate assessment.`,
  mortgage: `This tool calculates U.S. and Canadian monthly mortgage payments based on principal, interest and term. U.S. mortgages are compounded monthly while Canadian mortgages are compounded semi-annually.`
};
