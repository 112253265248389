import React from "react";
import { devOptions } from "./ProjectsData";

const ProjectsDeveloperFilter = (props) => {

  const clickHandler = () => {
    let e = document.getElementById("pdeveloper");
    let value = e.value;

    props.filterFunction(value)
  };
  return (
    <div id="location">
      <h5>Developer</h5>

      <select id="pdeveloper" name="pdeveloper" onChange={clickHandler} required>
        <option value="">Select a developer</option>

        {devOptions.map((developerName, index) => (
          <React.Fragment key={"d" + index}>
            <option value={index} >{developerName}</option>
          </React.Fragment>
        ))}

      </select>
    </div>
  );
};

export default ProjectsDeveloperFilter;
