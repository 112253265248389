import { Image } from "react-bootstrap";
import { AboutData } from "./AboutData";
import ContactInfo from "./ContactInfo";

const Person = (props) => {
  const clickHandler = () => {
    props.myFunction(props.index, props.photo);
  };

  return (
    <div className="employee">
      <div className="employeeImg">
        <Image src={props.src} alt={props.name} onClick={clickHandler} />
      </div>
      <div>{AboutData[props.index].name} </div>
      <ContactInfo index={props.index} />
    </div>
  );
};

export default Person;
