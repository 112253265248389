import { useState, useEffect } from "react";
import { IntroData } from "../IntroData";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalculator } from "@fortawesome/free-solid-svg-icons";
import { displayCalculator, hideCalculator } from "./ToolsUIFunctions";
import CostIndexCalculator from "./CostIndexCalculator";
import MortgageCalculator from "./MortgageCalculator";
import "./Tools.css";

const Tools = () => {
  const calculator = localStorage.getItem("calculator");

  const [costIndex, setCostIndex] = useState(false);
  const [mortgage, setMortgage] = useState(false);

  const costIndexClickHandler = () => {
    setCostIndex(true);
    displayCalculator("costIndex");
  };

  const mortgageClickHandler = () => {
    setMortgage(true);
    displayCalculator("mortgage");
  };

  const cancelCostHandler = () => {
    setCostIndex(false);
    hideCalculator("costIndex");
  };

  const cancelMortgageHandler = () => {
    setMortgage(false);
    hideCalculator("mortgage");
  };

  useEffect(() => {
    // Runs after the first render() lifecycle

    if (calculator == "ci") {
      setCostIndex(true);
      displayCalculator("costIndex");
      localStorage.setItem("calculator", "");
    }
  }, [calculator]);

  return (
    <section id="tools">
      <p id="intro">{IntroData["tools"]}</p>
      <div className="row" id="calculators">
        <div id="columnCost" className="column">
          <h4>Cost Index Calculator</h4>
          <div id="costIcon" onClick={costIndexClickHandler}>
            <FontAwesomeIcon className="icon" icon={faCalculator} />
          </div>
          {/* <button
            id="costIndexButton"
            className="btn btn-primary myButton"
            onClick={costIndexClickHandler}
          >
            Calculate
          </button> */}
        </div>
        <div id="columnMortgage" className="column">
          <h4>Mortgage Calculator</h4>
          <div id="mortgageIcon" onClick={mortgageClickHandler}>
            <FontAwesomeIcon className="icon" icon={faCalculator} />
          </div>
          {/* <button
            id="mortgageButton"
            className="btn btn-primary myButton"
            onClick={mortgageClickHandler}
          >
            Calculate
          </button> */}
        </div>
      </div>
      {costIndex && (
        <div id="costIndex" className="calculator">
          <CostIndexCalculator
            clickHandler={cancelCostHandler}
          ></CostIndexCalculator>
        </div>
      )}
      {mortgage && (
        <div id="mortgage" className="calculator">
          <MortgageCalculator
            clickHandler={cancelMortgageHandler}
          ></MortgageCalculator>
        </div>
      )}
    </section>
  );
};

export default Tools;
