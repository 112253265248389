import React, { useState } from "react";
import { Image, Modal } from "react-bootstrap";
import { IntroData } from "../IntroData";
import { AboutData } from "./AboutData";
import * as photos from "./PhotosData";
import ContactInfo from "./ContactInfo";
import Person from "./Person";
import "./People.css";

const People = () => {
  const employees = Object.keys(AboutData);

  const [index, setIndex] = useState(employees[0]);
  const [photo, setPhoto] = useState("");
  const [show, setShow] = useState(false);

  const closeHandler = () => setShow(false);
  const showHandler = () => setShow(true);

  const showMeTheModal = (index, name) => {
    setIndex(index);
    setPhoto(name);
    showHandler();
  };

  return (
    <section id="people">
      <div id="people_intro">
        <p>{IntroData["people"]}</p>
      </div>
      <div id="pictures">
        {employees.map((employee) => (
          <React.Fragment key={employee}>
            <Person
              src={photos[employee + "t"]}
              name={employee}
              index={employee}
              photo={photos[employee]}
              myFunction={showMeTheModal}
            />
          </React.Fragment>
        ))}
      </div>

      <Modal
        show={show}
        onHide={closeHandler}
        dialogClassName="people-modal"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <span style={{ color: "#68a4c4" }}>{AboutData[index].name} </span>
            <span style={{ fontSize: "12px" }}>{AboutData[index].title}</span>
            <div style={{ fontSize: "12px", color: "#68a4c4" }}>
              {AboutData[index].designation}
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ color: "gray" }}>
          <div className="row">
            <div className="image-container">
              <Image src={photo} alt="thumbnail" />
            </div>
            <div className="description-container">
              <p style={{ marginTop: "10px" }}>
                {AboutData[index].description}
              </p>
              <ContactInfo index={index} />           
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </section>
  );
};

export default People;
