const ProjectsFilter = (props) => {
  const clickHandler = () => {
    props.myFunction(props.id);
  };

  return (
    <div>
      <a id={props.id} className="options" onClick={clickHandler}>
        {props.text}
      </a>
    </div>
  );
};

export default ProjectsFilter;
