import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { displayService } from "./ServicesUIFunctions";

import { IntroData } from "../IntroData";
import { services, serviceRows } from "./ServicesData";
import Service from "./Service";
import "./Services.css";

const Services = () => {
  const MAX_WIDTH = 768;
  const service = localStorage.getItem("serviceKey");

  const [index, setIndex] = useState(0);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const displayBox = (index, paragraph) => {
    if (window.innerWidth <= MAX_WIDTH) {
      setIndex(index);
      handleShow();
    } else {
      displayService(paragraph, services[index].description, index);
    }
  };

  let currentIndex = 0;

  useEffect(() => {
    // Runs after the first render() lifecycle

    if (service != "" && service != null) {
      setIndex(service);
      handleShow();
      localStorage.setItem("serviceKey", "");
    }

    if (show) window.addEventListener("wheel", handleClose, true);
    return () => window.removeEventListener("wheel", handleClose, true);
  }, [show, service]);

  return (
    <section id="services">
      <div>
        <p>{IntroData["services"]}</p>
      </div>
      <div className="container">
        {serviceRows.map((row, i) => (
          <React.Fragment key={i}>
            <div className="col-sm-12">
              <div className="row">
                <Service
                  name={row[0].name}
                  title={row[0].title}
                  icon={row[0].icon}
                  index={currentIndex++}
                  paragraph={"p" + (i + 1)}
                  myFunction={displayBox}
                />
                <Service
                  name={row[1].name}
                  title={row[1].title}
                  icon={row[1].icon}
                  index={currentIndex++}
                  paragraph={"p" + (i + 1)}
                  myFunction={displayBox}
                />
                {currentIndex != 14 && <Service
                  name={row[2].name}
                  title={row[2].title}
                  icon={row[2].icon}
                  index={currentIndex++}
                  paragraph={"p" + (i + 1)}
                  myFunction={displayBox}
                />}
              </div>
            </div>

            <p className="togContent" id={"p" + (i + 1)}></p>
          </React.Fragment>
        ))}
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        onScroll={handleClose}
        dialogClassName="service-modal"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "#68a4c4" }}>
            {services[index].heading}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p style={{ color: "gray" }}>{services[index].description}</p>
        </Modal.Body>
      </Modal>
    </section>
  );
};

export default Services;
