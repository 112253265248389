import { costIndexRate, costIndexBaseCost } from "./ToolsData";
import CostIndexResultTable from "./CostIndexResultTable";
import MortgageResultTable from "./MortgageResultTable";

const round2d = (number) => {
  return 0.01 * Math.round(100 * number);
};

const floor2d = (number) => {
  return 0.01 * Math.floor(100 * number);
};

const ceil2d = (number) => {
  return 0.01 * Math.ceil(100 * number);
};

//Calculate Cost Index
export const calculateCostIndex = (ciFunction) => {
  const buildingType = document.getElementById("cibuilding");
  const ciBuildingValue =
    buildingType.options[buildingType.selectedIndex].value;
  const ciBuildingText = buildingType.options[buildingType.selectedIndex].text;
  const ciBaseCost = costIndexBaseCost[ciBuildingValue - 1];

  const buildingLocation = document.getElementById("cilocation");
  const ciLocationValue =
    buildingLocation.options[buildingLocation.selectedIndex].value;
  const ciLocationText =
    buildingLocation.options[buildingLocation.selectedIndex].text;
  const ciBase = costIndexRate[ciLocationValue - 1]["cibase"];

  const buildingQuality = document.getElementById("ciquality");
  const ciQualityValue =
    buildingQuality.options[buildingQuality.selectedIndex].value;
  const ciQualityText =
    buildingQuality.options[buildingQuality.selectedIndex].text;
  const ciQualilty = costIndexRate[ciLocationValue - 1]["ci" + ciQualityValue];

  const ciCost = round2d(ciBaseCost * ciBase * ciQualilty).toFixed(2);

  return (
    <CostIndexResultTable
      building={ciBuildingText}
      location={ciLocationText}
      quality={ciQualityText}
      result={ciCost}
      myFunction={ciFunction}
    ></CostIndexResultTable>
  );
};

//Calcualte Mortgage
export const calculateMortgage = () => {
  const amortization = document.getElementById("amortization").value;
  let term = document.getElementById("term").value;
  const interest = document.getElementById("interest").value * 0.01;
  const principal = round2d(document.getElementById("principal").value);
  const downPayment = document.getElementById("downpayment").value * 0.01;
  const country = document.querySelector(
    'input[name="optionCountry"]:checked'
  ).value;
  let cdn = true;

  if (country == "usa") {
    cdn = false;
  }

  if (term > amortization) {
    term = amortization;
  }

  let rAmortization = ceil2d(principal * downPayment);
  let rPrincipal = principal - rAmortization;
  const rInterest = cdn ? Math.pow(1 + interest / 2, 1 / 6) : 1 + interest / 12;
  const rInterest2 = 1 / rInterest;

  let monthlyPayment = ceil2d(
    (rPrincipal * (rInterest - 1)) /
      (1 - Math.pow(rInterest, -(amortization * 12)))
  );

  let amountOwing =
    (rPrincipal -
      (rInterest2 * monthlyPayment * (1 - Math.pow(rInterest2, 12 * term))) /
        (1 - rInterest2)) /
    Math.pow(rInterest2, 12 * term);

  if (amountOwing < 0) {
    amountOwing = 0;
  }

  rAmortization = rAmortization.toFixed(2);
  rPrincipal = rPrincipal.toFixed(2);
  monthlyPayment = monthlyPayment.toFixed(2);
  amountOwing = amountOwing.toFixed(2);

  return (
    <MortgageResultTable
      amortization={rAmortization}
      principal={rPrincipal}
      payment={monthlyPayment}
      amount={amountOwing}
    ></MortgageResultTable>
  );
};
