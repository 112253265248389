import { initializeApp } from "firebase/app";
import {
  getDatabase,
  ref,
  onValue,
  orderByChild,
  equalTo,
  query,
  limitToLast,
  orderByValue,
} from "firebase/database";
import {
  getStorage,
  ref as storageRef,
  getDownloadURL,
} from "firebase/storage";
import { Link } from "react-router-dom";
import featuredProjects from "./ProjectsData";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAdQbGP0jADnHJ-vWCZ-ZNAq0jZZ-IdPrg",
  authDomain: "bdc-database.firebaseapp.com",
  databaseURL: "https://bdc-database-default-rtdb.firebaseio.com",
  projectId: "bdc-database",
  storageBucket: "bdc-database.appspot.com",
  messagingSenderId: "1213277862",
  appId: "1:1213277862:web:d9b8688a7e2e4c0f10b667",
};

const LOADING_TIME = 1500;
const NUM_MOST_RECENT = 24;
const app = initializeApp(firebaseConfig);
const db = getDatabase(app);
export let projects = [];
export let displayedProjects = [];
export let recentProjects = [];

//Display featured projects
export const loadProject = (myFunction) => {
  projects = [];

  featuredProjects.map((project) =>
    projects.push(
      <div key={project.id} className="project">
        <div className="row">
          <div className="columnPhoto">
            <img
              src={project.path}
              title={project.name}
              width="300px"
              onClick={() => myFunction(project.id - 1)}
            />
          </div>
          <div className="columnInfo">
            <div>
              <b>{project.title}</b>
            </div>
            <div>
              <p>{project.description}</p>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

//Get data from database and render onto the webpage
const render = (element, myFunction) => {
  const projectTitle = element.val().ptitle;
  const projectNumber = element.val().pnumber;
  const projectDescription = element.val().pdesc;
  const projectLocation = element.val().plocation;
  const projectDeveloper = element.val().pdeveloper;
  const projectImage = element.val().pimage;
  const storage = getStorage();
  let imagePath = "";

  getDownloadURL(storageRef(storage, projectImage)).then((url) => {
    imagePath = url;

    const project = (
      <div key={projectNumber} className="filteredProject">
        <img
          src={imagePath}
          title={`${projectTitle}, ${projectLocation}`}
          className="filteredProjectImage"
          onClick={() => myFunction(projects.indexOf(project))}
        />
        <div>
          <b>{projectTitle}</b>
        </div>
        <div>{projectLocation}</div>
      </div>
    );

    projects.push(project);
    displayedProjects.push({
      path: imagePath,
      title: projectTitle,
      name: `${projectTitle}, ${projectLocation}`,
      number: projectNumber,
      description: projectDescription,
    });
  });
};

//Display multiple projects' info on the webpage
const renderProjects = (dataRef, myFunction) => {
  projects = [];
  displayedProjects = [];

  onValue(dataRef, (snapshot) => {
    snapshot.forEach((childSnapshot) => {
      render(childSnapshot, myFunction);
    });
  });
};

const createClearAllTimeouts = () => {
  const noop = () => {};
  let firstId = setTimeout(noop, 0);

  return () => {
    const lastId = setTimeout(noop, 0);
    while (firstId !== lastId) {
      firstId += 1;
      clearTimeout(firstId);
    }
  };
};

//Clear all existing time outs
export const clearAllTimeouts = createClearAllTimeouts();

//Change color of selected option to blue
export const changeColor = (option, options, header = false) => {
  const chosenOne = document.getElementById(option);
  const all = document.getElementsByClassName(options);

  for (let i = 0; i < all.length; i++) {
    all[i].style.color = "gray";
    if (!header) {
      all[i].style.fontWeight = "normal";
    }
  }
  if (chosenOne != null) {
    chosenOne.style.color = "#68a4c4";
    chosenOne.style.fontWeight = "bold";
  }
};

//Filter projects based on their location
export const filterProjectsByLocation = (filterValue, myFunction) => {
  const tagRef = query(ref(db, "projects"), orderByChild("ptag"));
  const filterRef = query(tagRef, equalTo(filterValue));

  changeColor(filterValue, "options");
  renderProjects(filterRef, myFunction);

  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve();
    }, LOADING_TIME);
  });
};

//Filter projects based on their type of building
export const filterProjectsByType = (filterValue, myFunction) => {
  const tagRef = query(ref(db, "projects"), orderByChild("ptype"));
  const filterRef = query(tagRef, equalTo(filterValue));

  changeColor(filterValue, "options");
  renderProjects(filterRef, myFunction);

  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve();
    }, LOADING_TIME);
  });
};

//Filter projects based on their developer
export const filterProjectsByDeveloper = (filterValue, myFunction) => {
  const tagRef = query(ref(db, "projects"), orderByChild("pdeveloper"));
  const filterRef = query(tagRef, equalTo(filterValue));

  renderProjects(filterRef, myFunction);

  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve();
    }, LOADING_TIME);
  });
};

//Retrieve and display the most recent projects
export const retrieveRecentProjects = (myFunction) => {
  const tagRef = query(ref(db, "projects"), orderByValue("pnumber"));
  const filterRef = query(tagRef, limitToLast(NUM_MOST_RECENT));
  recentProjects = [];

  onValue(filterRef, (snapshot) => {
    snapshot.forEach((childSnapshot) => {
      const projectTitle = childSnapshot.val().ptitle;
      const projectNumber = childSnapshot.val().pnumber;
      const projectTag = childSnapshot.val().ptag;
      const projectLocation = childSnapshot.val().plocation;
      const projectImage = childSnapshot.val().pimage;
      const storage = getStorage();
      let imagePath = "";

      getDownloadURL(storageRef(storage, projectImage)).then((url) => {
        imagePath = url;

        if (!imagePath.includes("no_image.png")) {
          const project = (
            <div key={"r" + projectNumber} className="recentProject">
              <Link to="/projects">
                <img
                  src={imagePath}
                  width="50px"
                  height="50px"
                  className="recentProjectImage"
                  title={`${projectTitle}, ${projectLocation}`}
                  onClick={() => {
                    localStorage.setItem("recentKey", projectTag);
                    localStorage.setItem("recentKeyIndex", projectNumber);
                  }}
                />
              </Link>
            </div>
          );
          recentProjects.push(project);
        }
      });
    });
  });

  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve();
    }, LOADING_TIME);
  });
};
