import { useState } from "react";
import { calculateMortgage } from "./ToolsLogicFunctions";
import { IntroData } from "../IntroData";

const MortgageCalculator = (props) => {
  const [submitted, setSubmit] = useState(false);
  const [result, setResult] = useState();

  const submitHandler = (event) => {
    event.preventDefault();
    const tableData = calculateMortgage();
    setResult(tableData);
    setSubmit(true);
  };

  const mortgageForm = (
    <form onSubmit={submitHandler}>
      <table>
        <tbody>
          <tr>
            <th>Amortization (years)</th>
            <td>
              <input
                type="number"
                min="0"
                id="amortization"
                name="amortization"
                className="form-control"
                placeholder="eg. 25"
                required
              />
            </td>
          </tr>

          <tr>
            <th>Term (years)</th>
            <td>
              <input
                type="number"
                min="0"
                id="term"
                name="term"
                className="form-control"
                placeholder="eg. 5"
                required
              />
            </td>
          </tr>

          <tr>
            <th>Yearly Interest Rate (%)</th>
            <td>
              <input
                type="number"
                step="0.01"
                min="0"
                id="interest"
                name="interest"
                className="form-control"
                placeholder="eg. 7"
                required
              />
            </td>
          </tr>

          <tr>
            <th>Principal Amount ($)</th>
            <td>
              <input
                type="number"
                step="0.1"
                min="0"
                id="principal"
                name="principal"
                className="form-control"
                placeholder="eg. 100000"
                required
              />
            </td>
          </tr>

          <tr>
            <th>Down Payment (%)</th>
            <td>
              <input
                type="number"
                step="0.01"
                min="0"
                id="downpayment"
                name="downpayment"
                className="form-control"
                placeholder="eg. 25"
                required
              />
            </td>
          </tr>

          <tr>
            <th>Country</th>
            <td>
              <div>
                <input
                  type="radio"
                  name="optionCountry"
                  value="cdn"
                  defaultChecked
                />{" "}
                Canada
              </div>
              <div>
                <input type="radio" name="optionCountry" value="usa" /> United
                States
              </div>
            </td>
          </tr>

          <tr>
            <td></td>
            <td>
              <div>
                <input
                  type="submit"
                  value="Calculate"
                  className="btn btn-primary myButton"
                />
                <input
                  type="button"
                  value="Cancel"
                  className="btn btn-primary myButton cancelBtn"
                  onClick={() => {
                    props.clickHandler();
                    setSubmit(false);
                  }}
                />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </form>
  );

  return (
    <div>
      <div>
        <p>{IntroData["mortgage"]}</p>
      </div>
      <div>
        {mortgageForm}
        <br />
        <br />
        {submitted ? result : ""}
      </div>
    </div>
  );
};

export default MortgageCalculator;
