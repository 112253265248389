const MAX_WIDTH = 768;

export const toggleMenu = (scrolled = false) => {
  if (document.documentElement.clientWidth <= MAX_WIDTH) {
    const x = document.getElementById("navbar");
    const burgerIcon = document.getElementById("burger");
    const closeIcon = document.getElementById("close");

    if (x.className === "topnav" && !scrolled) {
      x.className += " responsive";
      burgerIcon.style.display = "none";
      closeIcon.style.display = "block";
    } else {
      x.className = "topnav";
      burgerIcon.style.display = "block";
      closeIcon.style.display = "none";
    }
  }
};
