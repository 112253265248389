//Display calculator form
export const displayCalculator = (calculator) => {
  const c = document.getElementById(calculator);
  const p = document.getElementById("intro");
  let b, icon, column;

  if (calculator == "costIndex") {
    // b = document.getElementById("costIndexButton");
    icon = document.getElementById("costIcon");
    column = document.getElementById("columnMortgage");
  } else if (calculator == "mortgage") {
    // b = document.getElementById("mortgageButton");
    icon = document.getElementById("mortgageIcon");
    column = document.getElementById("columnCost");
  }

  // b.style.display = "none";
  p.style.display = "none";
  icon.style.display = "none";
  column.style.display = "none";
  window.scrollTo(0, 0);
};

//Hide calculator form
export const hideCalculator = (calculator) => {
  const c = document.getElementById(calculator);
  const p = document.getElementById("intro");
  let b, icon, column;

  c.style.opacity = 0;
  c.style.height = 0;
  p.style.display = "block";

  if (calculator == "costIndex") {
    // b = document.getElementById("costIndexButton");
    icon = document.getElementById("costIcon");
    column = document.getElementById("columnMortgage");
  } else if (calculator == "mortgage") {
    // b = document.getElementById("mortgageButton");
    icon = document.getElementById("mortgageIcon");
    column = document.getElementById("columnCost");
  }

  // b.style.display = "inline-block";
  icon.style.display = "block";
  column.style.display = "inline-block";
};
