import React, { useState } from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import { Image } from "react-bootstrap";
import vancouver from "../../../images/slides/main.png";
import burnaby from "../../../images/slides/burnaby.PNG";
import burnaby2 from "../../../images/slides/burnaby2.PNG";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

const PhotoSlider = () => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    useTransform: false,
  };

  return (
    // <Slider {...settings}>
    <div>
      <Link to="/projects">
        <Image src={vancouver} className="slidePhoto" id="mainPhoto"></Image>
        <p style={{ color: "#C05173", fontSize: "12px" }}>* Red Shades indicate BDC projects</p>
      </Link>
    </div>
    //   <div>
    //     <Link to="/projects">
    //       <Image src={burnaby} className="slidePhoto"></Image>
    //     </Link>
    //   </div>
    //   <div>
    //     <Link to="/projects">
    //       <Image src={burnaby2} className="slidePhoto"></Image>
    //     </Link>
    //   </div>
    // </Slider>
  );
};

export default PhotoSlider;
