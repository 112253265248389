import "./Company.css";

const Company = () => {
  return (
    <section id="company">
      <div>
        <p>
          Butterfield Development Consultants Ltd, established in 1988 by
          professionals dedicated to improve and advance the role of Cost
          Consultants in Canada.
        </p>
        <p>
          BDC’s policy of only employing highly qualified individuals with
          extensive experience, has resulted in a reputation for providing
          clients with practical advice of the highest calibre. The diversity of
          our client base attests to our ability to adapt critical thinking.
          Butterfield Development Consultants provides a wide range of services
          to the development community, specialising in all aspects of
          construction cost management.
        </p>
        <p>
          Our clients include banks, financial institutions, governments,
          pension funds, asset and fund managers, developers and landlords.
          Clients of BDC enjoy a level of confidence stemming from years of
          reliable, concise and accurate reporting. Reporting which enhances
          clients’ ability to make choices with clarity and confidence, while
          maximizing value.
        </p>
        <p>
          The BDC team is comprised of individuals qualified in the fields of
          Quantity Surveying, Engineering, Project Management and Accounting.
          Headquartered in downtown Vancouver, BDC maintains associations with
          equally qualified professionals in other major centres including
          Toronto, Calgary, Edmonton as well as in the US. This affords BDC the
          ability to provide the same high quality of service in locations
          outside of BC.
        </p>
        <p>
          BDC’s reputation extends within our professional organisations,
          providing technical expertise for the benefit of other members. BDC
          contributes to the development Industry by providing up to date Cost
          Information on our Web based Construction Cost index. BDC is also the
          only firm chosen to provide Canadian Construction Cost information for
          the internationally renowned Rawlinsons’ publication.
        </p>
        <p>
          We provide unbiased, independent advice to the development industry,
          so that building owners can enjoy increased productivity and
          efficiency in the design and construction process. Design consultants
          are served by advice on maintaining control of all aspects relating to
          cost, quality and performance and the integration of cost control.
          This facilitates value performance analysis which helps ensure that
          value is optimized. Our objective is to deliver the project on budget,
          on schedule and in a cost-effective manner.
        </p>
        <p>
          BDC spans the skills of project management, construction economics,
          quantity surveying and financial management with research to enhance
          the design, documentation, tendering and construction processes. Cost
          data, schedules and support reports are warehoused on our in house
          systems where they provide BDC with a rich resource of cross
          referenceable construction data. Custom programs analyze this data to
          enhance client service, delivering accurate measurable results
          immediately and directly to our clients’ desktop.
        </p>
        <p>
          Our services allow financial institutions, developers and designers to
          apply effective controls and accountability to the development process
          from the planning stage, through construction and in the management of
          successfully completed buildings. We strive to be Canada’s leading and
          most trusted provider of cost, value and project management services.
        </p>
        <p>
          At BDC we measure our success by the success of our clients and the
          quality of our relationships.
        </p>
      </div>
    </section>
  );
};

export default Company;
