import { useEffect } from "react";
import { Routes, Route, BrowserRouter, useLocation } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import Header from "./components/ui/Header/Header";
import Footer from "./components/ui/Footer/Footer";
import Home from "./components/routes/Home/Home";
import Company from "./components/routes/Company/Company";
import People from "./components/routes/People/People";
import Services from "./components/routes/Services/Services";
import Projects from "./components/routes/Projects/Projects";
import Tools from "./components/routes/Tools/Tools";
import Careers from "./components/routes/Careers/Careers";
import Wrapper from "./components/routes/Wrapper";
import "./App.css";

const Body = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.pathname == "/")
      document.body.style.backgroundColor = "#f9f9f9";
    else document.body.style.backgroundColor = "white";
  }, [location]);

  return (
    <div id="main-body">
      <TransitionGroup component={null}>
        <CSSTransition
          key={location.key}
          classNames="fade"
          timeout={3000}
          exit={false}
        >
          <Routes>
            <Route exact path="/" element={<Home />}></Route>
            <Route exact path="/company" element={<Company />}></Route>
            <Route exact path="/people" element={<People />}></Route>
            <Route exact path="/services" element={<Services />}></Route>
            <Route exact path="/projects" element={<Projects />}></Route>
            <Route exact path="/tools" element={<Tools />}></Route>
            <Route exact path="/careers" element={<Careers />}></Route>
          </Routes>
        </CSSTransition>
      </TransitionGroup>
    </div>
  );
};

export default function App() {
  return (
    <div>
      <BrowserRouter>
        <Header></Header>
        <Wrapper>
          <Body />
        </Wrapper>
        <Footer></Footer>
      </BrowserRouter>
    </div>
  );
}
