const CostIndexResultTable = (props) => {
  return (
    <div>
      <p>
        <i>{props.building}</i>
        <br />
        <i>{props.quality}</i> in <i>{props.location}</i>
        <br />
        <br />
        Estimated Cost per Square Foot: 
        <b>{` ${props.result} (incl. PST)`}</b>
      </p>
      <button className="btn btn-primary myButton" onClick={props.myFunction}>
        Start New Estimate
      </button>
      <p>
        <br />
        The above represents the expected on site construction costs in a
        normal, competitive environment.
      </p>
      <p>
        Areas are based on above ground, gross floor, calculations.
        <br />
        Costs should be adjusted for site specific conditions.
        <br />
      </p>
      <p id="disclaimer" style={{color:"red",fontSize:"14px",fontStyle:"italic"}}>
        These results are a General Guide only and should NOT be applied to specific properties, without Consultation with BDC.
        <br />
        <br />
        For Cost of Replacements and/or for Insurance Purposes, please contact Ian Butterfield at 604-622-1950 or <a href="mailto:ian@bdconsultants.com" style={{textDecoration:"underline"}}>ian@bdconsultants.com</a> 
      </p>
    </div>
  );
};

export default CostIndexResultTable;
