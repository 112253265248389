import { NavLink } from "react-router-dom";
import logo from "../../../images/logos/BDCLogo.png";
import { Navbar, Container, Image } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";
import { changeColor } from "../../routes/Projects/ProjectsFunctions";
import { toggleMenu } from "./HeaderUIFunctions";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Header.css";

const Header = () => {
  const toggleMenuHandler = (id) => {
    toggleMenu();
    changeColor(id, "navOptions", true);
  };

  const burgerMenuHandler = (e) => {
    e.preventDefault();
    toggleMenu();
  };

  return (
    <div id="navbar" className="topnav">
      <Navbar bg="white" variant="light" style={{ zIndex: "1000" }} fixed="top">
        <Container>
          <a href="/">
            <Image id="logo" src={logo} alt="logo"></Image>
          </a>
          <div className="ml-auto navLinks">
            <NavLink
              to="/"
              id="navHome"
              className="navOptions"
              onClick={() => toggleMenuHandler("navHome")}
            >
              Home
            </NavLink>
            <NavLink
              to="/company"
              id="navCompany"
              className="navOptions"
              onClick={() => toggleMenuHandler("navCompany")}
            >
              Company
            </NavLink>
            <NavLink
              to="/people"
              id="navPeople"
              className="navOptions"
              onClick={() => toggleMenuHandler("navPeople")}
            >
              People
            </NavLink>
            <NavLink
              to="/services"
              id="navServices"
              className="navOptions"
              onClick={() => toggleMenuHandler("navServices")}
            >
              Services
            </NavLink>
            <NavLink
              to="/projects"
              id="navProjects"
              className="navOptions"
              onClick={() => toggleMenuHandler("navProjects")}
            >
              Projects
            </NavLink>
            <NavLink
              to="/tools"
              id="navTools"
              className="navOptions"
              onClick={() => toggleMenuHandler("navTools")}
            >
              Tools
            </NavLink>
            <NavLink
              to="/careers"
              id="navCareers"
              className="navOptions"
              onClick={() => toggleMenuHandler("navCareers")}
            >
              Careers
            </NavLink>
            <a href="#!" className="icon" onClick={burgerMenuHandler}>
              <FontAwesomeIcon
                style={{ fontSize: "5ex" }}
                icon={faBars}
                id="burger"
              />
              <FontAwesomeIcon
                style={{ display: "none" }}
                icon={faTimes}
                id="close"
              />
            </a>
          </div>
        </Container>
      </Navbar>
    </div>
  );
};

export default Header;
