export const displayService = (paragraph, content, index) => {
  const p = document.getElementById(paragraph);
  const headers = document.getElementsByTagName("h4");
  const header = headers.item(index);

  if (header.style.color != "" && header.style.color != "grey") {
    hideService(paragraph, index);
  } else {
    const paragraphs = document.getElementsByClassName("togContent");
    const icons = document.getElementsByClassName("icon");
    const icon = icons.item(index + 1);

    for (const paragraph of paragraphs) {
      paragraph.style.opacity = 0;
      paragraph.style.height = 0;
      paragraph.style.marginTop = 0;
      paragraph.style.marginBottom = 0;
    }

    p.style.opacity = 1;
    p.style.height = "auto";
    p.style.marginTop = "2rem";
    p.style.marginBottom = "2rem";
    p.innerHTML = content;

    // if (window.innerHeight > p.offsetHeight)
    //   p.scrollIntoView({ behavior: "smooth", block: "end" });
    // else p.scrollIntoView({ behavior: "smooth", block: "start" });

    for (const header of headers) {
      header.style.color = "grey";
    }
    header.style.color = "#68a4c4";

    for (const icon of icons) {
      if (icon != null) icon.style.color = "grey";
    }
    if (icon != null) icon.style.color = "#68a4c4";
  }
};

const hideService = (paragraph, index) => {
  const p = document.getElementById(paragraph);
  const header = document.getElementsByTagName("h4").item(index);
  const icon = document.getElementsByClassName("icon").item(index + 1);

  p.style.opacity = 0;
  p.style.height = 0;
  p.style.marginTop = 0;
  p.style.marginBottom = 0;

  if (icon != null) icon.style.color = "grey";
  header.style.color = "grey";
};
