import { AboutData } from "./AboutData";

const ContactInfo = (props) => {
  return (
    <div>
      <a
        href={`mailto:${AboutData[props.index].email}`}
        style={{ color: "#68a4c4" }}
      >
        {AboutData[props.index].email}
      </a>
      <br />
      <a href={`tel:+1${AboutData[props.index].phone}`}>
        {AboutData[props.index].phone}
      </a>
      <br />
      <a href={AboutData[props.index].vcard} download style={{ color: "#68a4c4", textDecoration: "underline" }}>vCard</a>
    </div>
  );
};

export default ContactInfo;
