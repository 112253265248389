import {
  faAdjust,
  faCalendar,
  faCertificate,
  faDesktop,
  faDollarSign,
  faEdit,
  faFile,
  faForward,
  faPencilAlt,
  faTasks,
} from "@fortawesome/free-solid-svg-icons";

export const services = [
  {
    heading: "Loan Monitoring",
    description: `Project Monitoring enables lenders to take advantage of professional cost and project management expertise ensuring protection of their investment. We protect our client’s interests by identifying and advising on the risks associated with projects that are not under their direct control.
  
      BDC provides project-monitoring services that facilitate the flow of information between lenders and developers with accurate, timely analysis and clear concise reporting. Project monitoring services usually entail a two-step process that starts with an initial project review prior to commencement on site and subsequent monitoring function throughout the construction period.
  
      We provide certainty for lenders prior to the commencement of the project as our cost and project management expertise enables us to review proposals from a developer’s perspective. We understand how construction projects are delivered, the items that need to be considered and the pitfalls that developers face.
  
      Throughout the construction period we provide a much needed interface between the lender and developer. We ensure that the developer is provided with the appropriate funds as the project progresses, yet ensure the lenders are not exposed to the problems inherent in development and construction. This approach to project monitoring is one of identifying issues early in the process and working with the parties involved to mitigate problems before they arise.
    
      Monitoring services include: 
  
      - Ensuring that the project budget is reasonable, adequate and realistically allocated to the various hard and soft cost budget categories.
      - Analysis and reporting on the existence and adequacy of supporting documentation, schedules, geotechnical reports, environmental recommendations, cash flows, contracts, bonds, insurances, etc.
      - Site visits during construction to accurately assess the value of work in place and the cost to complete.`,
  },
  {
    heading: "Cost of Replacement for Insurance",
    description:
      "BDC’s cost index is the leading guide to the insurance industry when assessing the cost to replace buildings. BDC reports step beyond the general guide when estimates are provided for specific buildings. Using BDC’s extensive data base current costs are estimated for buildings with annual updates made to reflect market conditions. Property Managers, Building Owners and Strata Corporations benefit in the knowledge that they are neither over insured nor under insured, and have accurate rates for insurance.",
  },
  {
    heading: "Building Condition Reports",
    description:
      "BDC provide Building Condition Reports to lenders, property managers and building owners, usually at the time of new or re-financing. Conclusions are reported on the expected economic life of a building, based on a visual inspection and assessments of maintenance programs and repair schedules.",
  },
  {
    heading: "Depreciation Reports",
    description: `BDC is available to provide a team of property professionals to provide a Depreciation Report for all property sectors under Strata management.
  
      BDC will manage and complete this process to provide the following:
      
      A visual inspection of the subject building and site to report on the current condition, highlighting any repairs and maintenance work required;
      A lifecycle analysis for the building and site over 30 years forecasting anticipated maintenance, repair and replacement costs that will be payable by the strata corporation
      
      The Depreciation Report will be completed by a team of property professionals, including:
      
      - Structural engineers
      - Building envelope consultants
      - Mechanical & Electrical Engineers
      - Elevator consultants
      - Quantity Surveyors
      
      BDC’s personable team of professionals is pleased to work with Strata Corporations to discuss individual requirements and assess which level of reporting would be required under the Strata Property Act.`,
  },
  {
    heading: "Cost Planning",
    description:
      "When cost planning is applied at the very early stages of a project, alternative concepts can be properly evaluated and realistic budgets can be established. This helps avoid delays and conflicts that often arise when budget and concept issues need to be revisited later in the design process. To maximize the benefit of this service, BDC will become an integral part of the design and construction process from the outset. By maintaining the confidence of the design team, respecting the project design, and retaining independence, BDC is an objective source of information and solutions by all.",
  },
  {
    heading: "Project Management",
    description: `BDC consolidates the skills of project management, construction economics, quantity surveying and financial management with research to enhance the design, documentation, tendering and construction processes. Cost data, schedules and supporting reports are monitored and maintained within our custom designed software systems. In-house developed software allow BDC to deliver service immediately and directly to our clients’ desktop.
  
      Our Project Management experience covers the four main phases of a project – initiation and concept, planning and development, implementation and execution, leading to handover and evaluation.
      
      Successfully completing any development project depends on a systematic approach to the work and the complete commitment of the project management team. BDC can undertake the management of projects completely, or act in a more specific role providing advice on elements of a project. Regardless of the level of involvement required, BDC ensures complete commitment to both our clients and the overall success of the project.`,
  },
  {
    heading: "Quantity Surveying",
    description: `Cost management is crucial to planning and developing construction projects of any nature. Identifying and achieving maximum value is the keystone to any integrated design and construction process. BDC offers meaningful professional advice, supported by relevant data, to suit any given project delivery method, at any time in the integrated project cycle. We provide a framework upon which our clients are able to develop and manage their projects.
  
      Designers and owners are served by advice on and control of all aspects relating to cost, quality and performance and the integration of cost control with value performance analysis helps ensure that value is optimized. Our objective is to deliver the project on budget, on schedule and in a cost-effective manner.`,
  },
  {
    heading: "Scheduling",
    description:
      "Programming compliments other aspects of the cost control process. Accurate timelines are established in order to plan and monitor the construction process. Critical paths are identified as an effective tool to help ensure that a project remains on track.",
  },
  {
    heading: "Contingency Risk Analysis",
    description: `BDC is pleased to introduce a CONTINGENCY RISK ANALYSIS with our reports.
  
      BDC has developed software that will analyze individual elements of construction budgets, assessing their potential for over runs.
      
      These individual results are collated in order to advise banks and developers the amount of contingency required to adequately cover the risks.
      
      The software allows for the tracking and updating of the elements for both construction hard costs and development soft costs.
      
      The result is an accurate assessment of risk that can be traced to the individual elements that form the greater part of this risk, so that such can be monitored closely.`,
  },
  {
    heading: "Feasibility Studies",
    description: `BDC draws on our extensive cost database to produce reports that enable developers to assess a projects viability. Investors are served by concise, well researched data, presented by independent professionals.
  
      Applying best practices in Quantity Surveying, Value Management and Scheduling to a project, enables the design team to create projects on time and within budget.`,
  },
  {
    heading: "Builders Lien Act Certification",
    description: `The British Columbia Builders’ Lien Act, requires the appointment of a Payment Certifier for every project of $100,000 in value. Rules require that contracts, payments and schedules adhere to strict procedures.
  
      BDC know the required procedures; we are experienced in the set-up and monitoring of the payment process, in order to keep projects in line with the rules of the Act.
      
      BDC are independent professionals, trusted by building owners, contractors and designers.`,
  },
  {
    heading: "Construction Management",
    description: `In a sub role to the Project Manager, BDC can offer Supervision and Management of the contractual process. Working directly with trades, BDC will manage the costs, schedule and quality of construction.
  
      The goal is to produce buildings in an economical, timely manner with work to code and to the highest quality.`,
  },
  {
    heading: "Technical Due Diligences",
    description: `Whether for disputes, technical audits or Value Management Assessments, BDC can apply our cost expertise to various building components and procedures.
  
      BDC provides independent advice to owners, designers and legal professionals. Our evaluation may be as an independent report, supporting documentation or as expert witnesses.`,
  },
  {
    heading: "Value Management",
    description: `Value Management is an interactive process created to ensure that the best solutions are employed to reduce initial capital and long-term operational costs while preserving the aesthetic, utility, function and performance characteristics of your project. By employing Value Management techniques and bringing together in one place all of the various disciplines required to design your project, cost reductions are achieved without compromising the integrity of projects.
  
      For the Value Management process to be successful, the VM team needs to know the capital and life cycle cost of the project going-in and the value/cost of any alternatives proposed during the session. BDC will provide the initial project costs for the session and evaluate value/cost alternatives. This real time interactivity promotes a continuous flow of ideas and prevents the disruptions that would occur if the group were continually waiting for costs to be assessed.`,
  },
];

export const serviceRows = [
  [
    {
      name: "loan",
      title: "Loan Monitoring",
      icon: faDesktop,
    },
    {
      name: "insurance",
      title: "Cost of Replacement for Insurance",
      icon: faDollarSign,
    },
    {
      name: "condition",
      title: "Building Condition Reports",
      icon: faEdit,
    },
  ],
  [
    {
      name: "depreciation",
      title: "Depreciation Report",
      icon: faEdit,
    },
    {
      name: "cost",
      title: "Cost Planning",
      icon: faDollarSign,
    },
    {
      name: "project",
      title: "Project Management",
      icon: faFile,
    },
  ],
  [
    {
      name: "quantity",
      title: "Quantity Surveying",
      icon: faAdjust,
    },
    {
      name: "schedule",
      title: "Scheduling",
      icon: faCalendar,
    },
    {
      name: "contigency",
      title: "Contingency Risk Analysis",
      icon: faTasks,
    },
  ],
  [
    {
      name: "feasibility",
      title: "Feasibility Studies",
      icon: faPencilAlt,
    },
    {
      name: "certification",
      title: "Builders Lien Act Certification",
      icon: faCertificate,
    },
    {
      name: "construction",
      title: "Construction Management",
      icon: faFile,
    },
  ],
  [
    {
      name: "technical",
      title: "Technical Due Diligences",
      icon: faForward,
    },
    {
      name: "value",
      title: "Value Management",
      icon: faFile,
    },
    {
      name: "",
      title: "",
      icon: null,
    },
  ],
];
