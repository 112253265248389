// ScrollToTop.jsx
import { useEffect } from "react";
import { useLocation } from "react-router";
import { toggleMenu } from "../ui/Header/HeaderUIFunctions";

const Wrapper = (props) => {
  const location = useLocation();

  useEffect(() => {
    const navigationBar = document.getElementById("navbar");
    let start = null;

    if (location.pathname == "/services" && location.hash != "") {
      navigationBar.style.display = "none";
    }

    window.scrollTo(0, 0);
    window.onwheel = (e) => {
      if (e.deltaY >= 0) {
        // Scrolling Down with mouse
        if (navigationBar.style.display != "none")
          navigationBar.style.display = "none";
      } else {
        // Scrolling Up with mouse
        if (navigationBar.style.display != "block") {
          toggleMenu(true);
          navigationBar.style.display = "block";
        }
      }
    };

    const touchStartHandler = (e) => {
      start = e.changedTouches[0];
    };

    const touchEndHandler = (e) => {
      let end = e.changedTouches[0];

      if (end.screenY - start.screenY > 0) {
        //Scrolling up with touch screen
        if (navigationBar.style.display != "block") toggleMenu(true);
        navigationBar.style.display = "block";
      } else if (end.screenY - start.screenY < 0) {
        //Scrolling down with touch screen
        if (navigationBar.style.display != "none")
          navigationBar.style.display = "none";
      }
    };

    window.addEventListener("touchstart", touchStartHandler);
    window.addEventListener("touchend", touchEndHandler);

    return () => {
      window.removeEventListener("touchstart", touchStartHandler);
      window.removeEventListener("touchend", touchEndHandler);
    };
  }, [location]);

  return <>{props.children}</>;
};

export default Wrapper;
