import seaview from "../../../images/projects/projects-westvancouver-seaview.jpg";
import fifteen from "../../../images/projects/projects-vancouver-fifteenfifteen.jpg";
import royalbay from "../../../images/projects/projects-colwood-royalbay.jpg";
import portroyal from "../../../images/projects/projects-newwestminster-portroyal.jpg";
import waterfront from "../../../images/projects/projects-squamish-waterfrontlanding.jpg";
import lynnvaley from "../../../images/projects/projects-vancouver-lynnvalley.jpg";

import pandora from "../../../images/projects/projects-victoria-pandora.jpg";
import northmeridian from "../../../images/projects/projects-langley-northmeridianapartments.jpg";
import bristolheights from "../../../images/projects/projects-abbotsford-bristolheights.jpg";
import brickwatervillage from "../../../images/projects/projects-mapleridge-brickwatervillage(building4).jpg";


const featuredProjects = [
  { id: 1, path: seaview, title: "Cressey Seaview", name: "Cressey Seaview, West Vancouver", description: "Construction of a 16 storey residential tower containing 35 apartments/townhouses, constructed above a 2.5 level underground parkade." },
  { id: 2, path: fifteen, title: "Fifteen Fifteen", name: "Fifteen Fifteen, Vancouver", description: "Construction of a forty-two storey high-rise mixed-use building over a seven level underground parkade with public plaza." },
  { id: 3, path: royalbay, title: "Royal Bay Sectors", name: "Royal Bay Sectors, Colwood", description: "Site servicing works for a construction of 77 single-family lots." },
  { id: 4, path: portroyal, title: "Port Royal by Aragon", name: "Port Royal by Aragon, New Westminster", description: "A multi-phased development by Aragon; Construction of 87 residential units, wood framed, 4 levels plus mezzanine apartments." },
  { id: 5, path: waterfront, title: "Waterfront Landing", name: "Waterfront Landing, Squamish", description: "Construction of 11 wood-frame townhouse buildings with a central common park area with kid playground, community gardens, mail cour, and a flex use strata park." },
  { id: 6, path: lynnvaley, title: "Lynn Valley", name: "Lynn Valley, North Vancouver", description: "Construction of two mid-rise buildings containing 115 residential units, over four levels of underground parkade." }
];

export const images = [
  {
    original: seaview,
    thumbnail: seaview,
  },
  {
    original: pandora,
    thumbnail: pandora,
  },
  {
    original: northmeridian,
    thumbnail: northmeridian,
  },
  {
    original: brickwatervillage,
    thumbnail: brickwatervillage,
  },
  {
    original: waterfront,
    thumbnail: waterfront,
  },
  {
    original: bristolheights,
    thumbnail: bristolheights,
  },
];

export const devOptions = [
  "Adera",
  "Adisa Homes",
  "Algra Bros Developments Ltd.",
  "Allaire Living",
  "Amacon",
  "Aquilini Development",
  "Aragon",
  "Archia Development Ltd.",
  "Boffo Developments",
  "Bogner Development Group Ltd.",
  "Bosa Properties Inc.",
  "Catalyst Community Developments",
  "Citimark",
  "Concord Pacific",
  "Cressey Developments ",
  "Cross Developments",
  "Darwin",
  "Dawson & Sawyer",
  "Dimex Group",
  "Domus Homes",
  "Endure Projects Inc.",
  "Executive TFN Development GP Ltd.",
  "Falcon Homes",
  "Flood Tide Capital Corp.",
  "Formwerks",
  "Gablecraft Homes",
  "Hayer Homes",
  "Headwater Projects",
  "Highstreet Developments",
  "Infinity Properties",
  "Jameson Development Corp.",
  "Kang & Gill Construction Ltd.",
  "Karagon Group Ltd.",
  "Kutak Developments",
  "Ledingham McAllister",
  "Liberty Homes Ltd.",
  "Lida Development Inc.",
  "Listraor Homes Ltd.",
  "Marcon",
  "Morningstar Homes",
  "Mosaic Homes",
  "Natural Balance Homes",
  "Nicolaou Properties Ltd.",
  "Nobleland Developments Ltd.",
  "Onni",
  "OpenForm Properties",
  "Orr Development",
  "Parcel 7 Homes Ltd.",
  "Peterson Group",
  "Pinnacle",
  "Platform Properties Ltd.",
  "Pollyco",
  "Polygon Homes Ltd.",
  "Porte",
  "QuadReal",
  "Redekop Ferrario Properties Corp.",
  "RKDI Homes Ltd.",
  "Royale Properties",
  "Sanford Housing Society",
  "Sightline Properties",
  "Stonewater Ventures Ltd.",
  "Strand",
  "Summit Pacific Properties Ltd.",
  "Ulmus Development Ltd.",
  "VanMar Developments Ltd.",
  "Vesta Properties Ltd.",
  "Wall Developments",
  "West Coast Estates",
  "Westbank Corp.",
  "Westhills Land Corp.",
  "WestUrban Developments Ltd.",
  "Whistler Housing Authority ",
  "Whitetail Homes",
];

export default featuredProjects;