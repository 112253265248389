import { useEffect, useState } from "react";
import { HashLink as Link } from "react-router-hash-link";
import { recentProjects } from "../../routes/Projects/ProjectsFunctions";
import { retrieveRecentProjects } from "../../routes/Projects/ProjectsFunctions";
import { MDBFooter, MDBContainer, MDBCol, MDBRow } from "mdb-react-ui-kit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faPhone, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import "./Footer.css";

const Footer = () => {
  const services = [
    "loan",
    "insurance",
    "condition",
    "depreciation",
    "cost",
    "project",
    "quantity",
    "schedule",
    "contigency",
    "feasibility",
    "certification",
    "construction",
    "technical",
    "value",
  ];
  const serviceTitles = [
    "Loan Monitoring",
    "Cost of Replacement for Insurance",
    "Building Condition Reports",
    "Depreciation Reports",
    "Cost Planning",
    "Project Management",
    "Quantity Surveying",
    "Scheduling",
    "Contingency Risk Analysis",
    "Feasibility Studies",
    "Builders Lien Act Certification",
    "Construction Management",
    "Technical Due Diligences",
    "Value Management",
  ];

  const [displayedProjects, setDisplayedProjects] = useState([]);

  const clickHandler = (id) => {
    localStorage.setItem("serviceKey", id);
  };

  useEffect(() => {
    retrieveRecentProjects().then((result) => {
      setDisplayedProjects(recentProjects);
    });
  }, []);

  return (
    <MDBFooter
      id="footer"
      style={{ backgroundColor: "#68a4c4" }}
      className="text-white text-left text-lg-left"
    >
      <MDBContainer className="p-4">
        <MDBRow>
          <MDBCol lg="5" md="12" className="mb-4 mb-md-4">
            <h5>Services</h5>
            <div style={{ marginTop: "1rem", marginRight: "10%" }}>
              {services.map((service) => {
                let index = services.indexOf(service);
                return (
                  <Link
                    to={"services#" + service}
                    className="text-white service-link"
                    onClick={() => clickHandler(index)}
                    key={index}
                  >
                    {serviceTitles[index]}
                    {index == services.length - 1 ? "" : ","}{" "}
                  </Link>
                );
              })}
            </div>
          </MDBCol>
          <MDBCol lg="3" md="6" className="mb-4 mb-md-0">
            <h5 style={{ marginBottom: "1rem" }}>Contact Us</h5>
            <FontAwesomeIcon icon={faHome} />
            <p>
              #501 - 837 West Hastings St
              {"\n"} Vancouver, BC
              {"\n"} Canada V6C 3N6
            </p>
            <FontAwesomeIcon icon={faPhone} />
            <p>
              <a href="tel:+16046884262">604-688-4262 (Phone)</a>
              <br />
              <a href="tel:+16046842678">604-684-2678 (Fax)</a>
            </p>
            <FontAwesomeIcon icon={faEnvelope} />
            <br />
            <a href="mailto:info@bdconsultants.com" className="text-white">
              info@bdconsultants.com
            </a>
          </MDBCol>
          <MDBCol lg="4" md="6" size="100" className="mb-4 mb-md-0">
            <h5>Recent Appointments</h5>
            <div id="recent">{displayedProjects.map((project) => project)}</div>
          </MDBCol>
        </MDBRow>
      </MDBContainer>

      <div
        id="copyright"
        className="text-center p-3"
        style={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }}
      >
        <a
          className="text-white"
          target="_blank"
          href="https://bdc-database.web.app/index.html"
        >
          &copy;{" "}
        </a>{" "}
        Copyright 1998-{new Date().getFullYear()} Butterfield Development
        Consultants. All Rights Reserved.
      </div>
    </MDBFooter>
  );
};

export default Footer;
