import ProjectsFilter from "./ProjectsFilter";

const ProjectsTypeFilter = (props) => {
  return (
    <div id="type">
      <h5>Building Type</h5>

      <ProjectsFilter
        id="lr"
        text="Low-Rise"
        myFunction={props.filterFunction}
      />
      <ProjectsFilter
        id="hr"
        text="High-Rise"
        myFunction={props.filterFunction}
      />
      <ProjectsFilter
        id="in"
        text="Industrial"
        myFunction={props.filterFunction}
      />
      <ProjectsFilter
        id="co"
        text="Commercial"
        myFunction={props.filterFunction}
      />
      <ProjectsFilter
        id="th"
        text="Townhomes"
        myFunction={props.filterFunction}
      />
      <ProjectsFilter
        id="sfh"
        text="Single Family Home"
        myFunction={props.filterFunction}
      />
    </div>
  );
};

export default ProjectsTypeFilter;
