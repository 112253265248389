export const AboutData = {
  jody: {
    name: "Jody Pamplin",
    title: "BSc (Hons), MRICS, PQS",
    designation: "Managing Director & CEO",
    description: `Joined BDC in 2006 after a building a five year foundation working as a construction consultant in London, UK. An active Member of the Royal Institute of Chartered Surveyors (RICS) and a Canadian Professional Quantity Surveyor (PQS), Jody has worked on the full range of Surveying, including Project Monitoring, Project Management, Contract Administration, Cost Planning and Building Inspections.

    Through his dedicated and successful career at BDC, Jody has recently been promoted to Managing Director and is continually looking for ways to improve BDC’s professionalism and level of care for clients.
        
    Jody’s current projects include:
    
    - Azure I & II, Burnaby 
    - Icon, Burnaby 
    - W42, Vancouver
    - Pura, Coquitlam
    - Moody Yards, Port Moody 
    - Birch & Broadway, Vancouver 
    - Format, Vancouver 

    Jody's recently completed projects include:
    
    - Cedar Creek, Burnaby 
    - Trio, Coquitlam 
    - Hensley & Westview, Coquitlam 
    - East Broadway & Commercial, Vancouver 
    - Mammoth Movie Studios, Burnaby 
    - Seaside, Tsawassen  
    - Evolve, UBC`,
    email: "jpamplin@bdconsultants.com",
    phone: "604.622.1942",
    vcard: "/vcards/Jody Pamplin.vcf",
  },
  ian: {
    name: "Ian Butterfield",
    title: "BSc (Hons), MRICS, MCIQS, PQS",
    designation: "Founder & Ambassador",
    description: `Founded BDC in 1988 after running the Mortgage Monitoring section of a national cost consulting practice. Past chairman of the BC branch of the Royal Institute of Chartered Surveyors. Author of the practice and procedures guide for Mortgage Monitoring Services by QS’s in BC. Ian has worked extensively in all areas of Quantity Surveying including Project Monitoring, Cost Planning, Scheduling and Project Management.

    Ian’s current projects include:
    
    - Fifteen Fifteen, Vancouver
    - Harwood, Vancouver
    - The Commons at Royal Bay, Colwood
    - Conservatory Tower, Vancouver
    - Waterfront Landing, Squamish
    - Tsawwassen Landing, Tsawwassen`,
    email: "ian@bdconsultants.com",
    phone: "604.622.1950",
    vcard: "/vcards/Ian Butterfield.vcf",
  },
  maryann: {
    name: "Maryann Stuart",
    designation: "Controller",
    description: `Joined BDC in 1997 with an accounting background previously working for a Vancouver based CA firm. During her 24 years in the Vancouver construction consulting industry, she has worked on a wide range of projects of commercial mixed used buildings and as wells as multimillion dollar residential developments. Her current focus is in Project accounting – internal operations (review deposits, payments, invoices and schedules in alignment with detailed budget costs).

    Maryann's recent projects include:
    
    - MP, Squamish
    - 1515 Georgia, Vancouver
    - Latimer, Langley
    - Sophora, Coquitlam
    - University District, Surrey
    - Azure I & II, Burnaby`,
    email: "mstuart@bdconsultants.com",
    phone: "604.622.1953",
    vcard: "/vcards/Maryann Stuart.vcf",
  },
  // chrys: {
  //   name: "Chrys Azozama",
  //   title: "MSc, PMP, GSC, MRICS, PQS",
  //   designation: "Senior Quantity Surveyor",
  //   description: `A Senior Quantity Surveyor with 19 years experience in Real Estate Development, Construction Project Management, Cost Consulting and Loan Monitoring, Chrys holds a master’s degree in Construction Project Management (Building Science), a bachelor’s degree in Construction Management, and a Diploma in Construction Engineering. Chrys is Certified Project Management Professional (PMP), a Certified Member of the Royal Institute of Chartered Surveyors (MRICS), a Professional Quantity Surveyor (PQS) and holds a Gold Seal Certificate (GSC). 
    
  //   Chrys’ current projects include:

  //   -	Sunset Industrial Centre
  //   -	The Grand Lion – Apartment / Mixed Use Building
  //   -	Nutri – Nation Buildings
  //   -	Willoughby Town Centre
  //   -	Hayer Town Centre
  //   -	Kanaka Creek – Industrial Building`,
  //   email: "chrys@bdconsultants.com",
  //   phone: "604.622.1936",
  //   vcard: "/vcards/Chrys Azozama.vcf",
  // },
  anthony: {
    name: "Anthony Craxton",
    title: "BSc (Eng), PQS",
    designation: "Senior Quantity Surveyor",
    description: `Joined BDC in 1998; a senior contracts manager at BDC with over 43 years of construction industry experience. Prior to joining BDC, Anthony has worked on construction projects spanning the globe including the position of project manager at the Dubai Port Authority in the UAE. In Vancouver Anthony has worked for Ledcor Contractors and BC Transit on multimillion dollar projects including the Millennium Line Skytrain Project. Anthony brings a wealth of expertise in the areas of project monitoring, budget estimating and building conditions inspection.

    Anthony's recent projects include:
    
    - Nadleh Whut’en Cultural Building, Fort Fraser
    - Sawmill Rebuild Project, Prince George
    - Brixton Station, Chilliwack
    - 5077 Northwoods, North Vancouver
    - Aston Row, Abbotsford`,
    email: "acraxton@bdconsultants.com",
    phone: "604.622.1939",
    vcard: "/vcards/Anthony Craxton.vcf",
  },
  // ben: {
  //   name: "Ben Kam",
  //   title: "BSc, FCIOB, MRICS, PQS",
  //   designation: "Senior Quantity Surveyor",
  //   description: `Ben has been a BDC Associate since 1992 after 15 years working internationally for developers and general contractors. Fluent in Cantonese, Mandarin and English.

  //   Professional members of Chartered Institute of Building (FCIOB), Royal Institute of Chartered Survey (MRICS) and Canadian Professional Quantity Surveyor (PQS). Ben’s extensive experience in development and construction facilitates his delivery of consulting services to clients at all development stages, including project financing monitoring, project management, contract administration and cost planning.
    
  //   Ben's featured projects include:
    
  //   - Bayshore Gardens, Highrises 7 Phases, Vancouver
  //   - Prospera Place, Arena, Kelowna
  //   - Summit Lodge, Hotel, Whistler
  //   - Viva Magnetics, Plant Building, Toronto
  //   - Gold's Gym, Fitness Centre Building, Langley
  //   - Trumpeters Landing, 3 Phases Apartments, Courtenay
  //   - Tempo, Office Building, 2 Phases, Abbotsford
  //   - Melrose Court, Townhouses, Richmond
  //   - Pacifica Harbour Front, Highrise, Nanaimo
  //   - De' Corize, Highrise, Surrey
  //   - Station One, Apartments, Maple Ridge
  //   - 2718 Highview Place, Single house, West Vancouver
  //   - Rodgers Creek, Residential Subdivisions, West Vancouver
  //   - Four Points Sheraton, Hotel, Calgary
  //   - Aberdeen Centre, Mall & Residences, Richmond
  //   - Sigma Hotel, Estevan, Melville, Moosomin, Saskatchewan`,
  //   email: "bkam@bdconsultants.com",
  //   phone: "604.622.1938",
  //   vcard: "/vcards/Ben Kam.vcf",
  // },
  tim: {
    name: "Tim Kim",
    title: "",
    designation: "Quantity Surveyor",
    description: `Joined BDC in 2013 with mathematic and programming background. He is responsible for the network and security as well as the internal software design and development within the company. During his time in BDC, he has been also pursuing his dream of becoming Quantity Surveyor by working extensively on project monitoring and sales review.

    Tim's recent projects include:
    
    - Sea to Sky Waterfront Landing, Squamish
    - University District & Parkway, Surrey
    - Latimer Heights & Village, Langley
    - Genesis & The Forme, Langley
    - Harrison Highlands, Agassiz
    - Provenance, Maple Ridge
    - Roslyn Ridge, Maple Ridge
    - Archer Green, Mission`,
    email: "tkim@bdconsultants.com",
    phone: "604.622.1952",
    vcard: "/vcards/Tim Kim.vcf",
  },
  amy: {
    name: "Amy E.S. Kwon",
    title: "",
    designation: "Quantity Surveyor",
    description: `Joined BDC in 2017 with over ten years of professional experience in accounting and architectural drafting. Amy is a member of the Construction Estimator Certified (CEC) profession and is currently pursuing Professional Quantity Surveyor (PQS) designation as well as completing her B. Tech in Construction Management program at BCIT.

    Amy’s recent projects include:
    -	Latimer Heights & Village, Langley
    -	Waterfront Landing, Squamish
    -	928 Commercial Dr, Vancouver
    -	2619 E Hastings, Vancouver
    -	Royal Bay, Colwood
    -	Sunset View Apartments, Powell River
    -	Glenpark Row, Kelowna`,
    email: "akwon@bdconsultants.com",
    phone: ".",
    vcard: "/vcards/Amy Kwon.vcf",
  },
  stephen: {
    name: "Stephen Lam",
    designation: "Quantity Surveyor",
    description: `Stephen Lam is a Junior Quantity Surveyor with a Bachelor of Science in Quantity Surveying. Fluent in English, Cantonese, and Mandarin, he possesses a solid foundation in construction economics, demonstrating a keen ability to manage complex projects and ensure financial precision.

    Having commenced his career in a first-tier construction consultancy firm in Hong Kong, Stephen joined BDC in 2022, bringing over one year of overseas experience to the team. His expertise extends to reviewing project budgets ranging from 10 million to over 100 million dollars, including high-rise residential buildings, townhouses, single-family homes, and site servicing. In this capacity, Stephen diligently assists and evaluates development proposals, ensuring strict adherence to lender engagement conditions.

    Stephen's proficiency is further evidenced by his assistive role in conducting progress claim report assessments, analyzing cash flows, assessing valuing adjustments, and monitoring job site progress from time to time.`,
    email: "slam@bdconsultants.com",
    phone: "604.622.1943",
    vcard: "/vcards/Stephen Lam.vcf",
  },
  zach: {
    name: "Zachary McCrea",
    title: "B.Tech., PQS",
    designation: "Quantity Surveyor",
    description: `Zachary joined BDC in 2020 with a background in estimating and pre-construction working with a tier 1 developer. Recently, Zachary has received his Professional Quantity Surveyor (PQS) certification as well as his B. Tech in Construction Management.

    Zachary has gained a wide range of experience working on projects throughout southern BC in project monitoring and estimating. 
    
    Zachary’s current projects include: 
    
    - Format, Vancouver 
    - W42, Vancouver
    - Hamilton, Burnaby 
    - Azure, Burnaby 
    - Florin, Coquitlam 
    - Precidia, Coquitlam
    
    Zachary’s recently completed projects include: 
    
    - HUB Place, Vancouver 
    - Cedar Creek, Burnaby
    - The Robinsons, Coquitlam
    - The CoMo, Coquitlam
    - The Approach, Whistler`,
    email: "zmccrea@bdconsultants.com",
    phone: "604.622.1935",
    vcard: "/vcards/Zachary McCrea.vcf",
  },
  euan: {
    name: "Euan McLean",
    title: "BSc, ACIOB, PQS",
    designation: "Senior Quantity Surveyor",
    description: `Euan co-founded BDC in 1988 after working with an international cost consulting practice. As a Project Manger responsible for the development and construction of a number of high profile projects, Euan has worked internationally in the consultancy and construction field on building and engineering projects.

    Euan’s current projects include:
    
    - Tsawwassen Springs, Tsawwassen
    - Kitchner Townhouses, South Surrey
    - The Regent on King Edward, Vancouver
    - Waterfront Re-Development, Squamish
    - La Galleria, Abbotsford
    - Wembley Townhouses, Richmond`,
    email: "emclean@bdconsultants.com",
    phone: "604.622.1951",
    vcard: "/vcards/Euan McLean.vcf",
  },
  joe: {
    name: "Joe Shannon",
    designation: "Quanity Surveyor",
    description: `Joe joined BDC in 2023 with 3 years of mixed experience in Quantity Surveying, including pre-construction working, estimating and project management. 
    
    Joe has a Bachelor of Science in Quantity Surveying from the University of Glasgow Caledonian, Scotland.`,
    email: "jshannon@bdconsultants.com",
    phone: "604.622.1954",
    vcard: "/vcards/Joe Shannon.vcf",
  },
  roy: {
    name: "Roy Lee",
    title: "P. Eng",
    designation: "Senior Engineer",
    description: `Joined BDC in 2007; Roy has over 20 years of experience in the field of civil engineering. As a registered Professional Engineer Roy played a key role in the design and management of over 150 commercial and residential land development projects. Roy has overseen all aspects of the development process and has worked closely with all municipal governments in the Lower Mainland. An alumnus of the faculty of Civil Engineering at the University of British Columbia Roy has a wealth of experience working with building codes and construction methods.

  Roy’s current projects include:
  
  - Briston Heights, Abbotsford
  - Ashbury & Oak, Langley
  - Harwood group Portfolio
  - Pender Properties Portfolio
  - Riversky, New Westminster
  - Mill District, Abbotsford
  - The Kirkland, Vancouver`,
    email: "rlee@bdconsultants.com",
    phone: "604.622.1941",
    vcard: "/vcards/Roy Lee.vcf",
  },
  // tony: {
  //   name: "Tony Tang",
  //   title: "",
  //   designation: "Project Coordinator",
  //   description: `Prior to joining BDC in 2002 Tony has built up 27 years of experience in the Vancouver construction consulting industry with a multi-national cost consulting practice. Working on a wide range of projects from the $650 million dollar GVRD Secondary Treatment Program at Annacis & Lulu Island to numerous multimillion dollar residential developments, Tony has been responsible for many key cost consulting components of these projects. An active member of the community Tony has generously lent his time and construction expertise to Habitat for Humanity for eleven years. Since spring 2013, Tony has switched his weekend volunteer hours to the Salvation Army.

  //   Tony's recent projects include:
    
  //   - Alden, Surrey
  //   - Byrne Park, Burnaby 
  //   - Cardinal, Coquitlam
  //   - Sophora Tower, Coquitlam
  //   - Ballantree North, Coquitlam
  //   - Westhampton, 2 Phases, Delta
  //   - Provenance South, Maple Ridge
  //   - Bridle Ridge, Maple Ridge
  //   - Archer Green, 4 Phases, Mission
  //   - Highline @ Garibaldi Springs, Squamish
  //   - Conservatory Tower, Vancouver`,
  //   email: "ttang@bdconsultants.com",
  //   phone: ".",
  //   vcard: "/vcards/Tony Tang.vcf",
  // },
  ken: {
    name: "Ken Dao",
    designation: "I.T.",
    description: `Ken joined BDC at the beginning of 2022 with a background in Computer Systems Technology from BCIT. 

    Ken has worked on the company’s new website and is currently working on an Excel add-in tool to help make creating reports easier for surveyors. 
    
    Ken also makes sure all computers and printers are in good working condition, and readily assists others whenever a technical issue comes up.
    `,
    email: "kdao@bdconsultants.com",
    phone: ".",
    vcard: "/vcards/Ken Dao.vcf",
  },
};
