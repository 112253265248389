const Job = (props) => {
  return (
    <div className="job">
      <div className="box-gray aligncenter" id={props.id}>
        <h4>{props.title}</h4>
        <p>{props.description}</p>
      </div>
      <div className="box-bottom"></div>
    </div>
  );
};

export default Job;
